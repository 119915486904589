import request from '@/utils/request'
const orderApi = {
  list: '/health_order',
  detail: id => `/health_order/${id}`,
  remove: id => `/health_order/cancel/${id}`,
  count: '/health_order/count'
}
export function list(parameter) {
  return request({
    url: orderApi.list,
    method: 'get',
    params: parameter
  })
}
export function detail(parameter) {
  return request({
    url: orderApi.detail(parameter),
    method: 'get'
  })
}
export function remove(parameter) {
  return request({
    url: orderApi.remove(parameter),
    method: 'patch'
  })
}
export function count() {
  return request({
    url: orderApi.count,
    method: 'get'
  })
}

<template>
  <div>
    <a-card :bordered="false" style="margin-bottom: 24px">
      <a-radio-group v-model="query.status" button-style="solid" @change="initData">
        <a-space :size="15">
          <a-radio-button v-for="(statusInfo, key) in statusOptions" :key="key" :value="statusInfo.value">
            {{ statusInfo.label }}<span v-if="$auth('order_status_count')">({{ orderCounts[key] }})</span>
          </a-radio-button>
        </a-space>
      </a-radio-group>
    </a-card>
    <a-card :bordered="false" style="margin-bottom: 24px">
      <a-row :gutter="[24, 0]">
        <a-form-model class="ant-advanced-search-form">
          <a-col :lg="6" :md="8" :sm="24">
            <a-form-model-item label="输入搜索：">
              <a-input-search
                v-model="query.accountOrNumber"
                placeholder="订单编号/用户账号"
                style="width: 100%"
                @search="initData"
              />
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item label="提交时间：">
              <a-date-picker
                v-model="query.startTimestamp"
                valueFormat="YYYY-MM-DD"
                style="flex: 1"
                @change="initData"
              />
              <span style="margin: 0 20px">至</span>
              <a-date-picker v-model="query.endTimestamp" valueFormat="YYYY-MM-DD" style="flex: 1" @change="initData" />
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="8" :sm="24">
            <a-form-model-item label="所属机构：">
              <a-input-search
                v-model="query.mechanismName"
                allowClear
                placeholder="请输入机构名称"
                style="flex: 1"
                @search="initData"
              />
            </a-form-model-item>
          </a-col>
        </a-form-model>
      </a-row>
    </a-card>
    <a-card :body-style="{ padding: 0 }">
      <div slot="title">
        <span>订单列表</span>
      </div>
      <main-table ref="table" v-if="$auth('order_list')"></main-table>
    </a-card>
  </div>
</template>

<script>
import MainTable from './components/Table'
import * as orderApi from '@/api/order'
import { ORDER_STATUS } from '@/store/dictionary'
export default {
  name: 'PermissionPostList',
  data() {
    return {
      statusOptions: Array.from(ORDER_STATUS),
      orderCounts: [],
      query: { status: '', accountOrNumber: '', startTimestamp: '', endTimestamp: '', mechanismName: '' },
    }
  },
  components: {
    MainTable,
  },
  activated() {
    if (this.ready) {
      this.initData()
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.ready = true
    })
    this.initData()
  },
  methods: {
    initData() {
      orderApi.count().then((res) => {
        const { allCount, toBePayCount, paidCount, cancelCount } = res.data
        this.orderCounts = [allCount, toBePayCount, paidCount, cancelCount]
      })
      this.$refs.table.initData(this.query)
    },
    onAdd() {
      const {
        $refs: { model },
        $nextTick,
        initData,
      } = this
      $nextTick(() =>
        model.showModal((e) => {
          initData()
        })
      )
    },
  },
}
</script>

<style lang="less" scoped>
.ant-advanced-search-form /deep/ .ant-form-item {
  display: flex;
  margin-bottom: 0;
}
.ant-advanced-search-form /deep/ .ant-form-item-control-wrapper {
  flex: 1;
}

/deep/.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #3a5ff8;
  border-color: #3a5ff8;
}
</style>
